<template>
	<div>
		<FlightHeader
			v-if="!isLoading && !error"
			class="flight-header"

			:flightName="'Flights to ' + flightsData.destination"
			:flightStartDate="startDate"
			:flightEndDate="startDate !== endDate ? endDate : false"

			:backLink="{ name: 'Flights Results' }"
		/>

		<Loader :loading="isLoading" :error="error" @refresh="init()"/>

		<div class="outer-wrapper">
			<div class="inner-wrapper" v-if="!isLoading && !error">
				<div class="heading">
					Flights
				</div>
				<div class="flights-date">
					{{ formatDate(flightsData.departure * 1000) }}
				</div>
				<!-- <pre>{{ flightsData }}</pre> -->
				<FlightCard
					class="flight-card"
					:name="'Flights to ' + flightsData.destination"
					:origin="flightsData.origin"
					:destination="flightsData.destination"
					:layoversTotal="outboundFlights.length - 1"
					:layoverLocations="outboundLayoverLocations"
					:arrivalDisplay="flightsData.arrival_display"
					:departureDisplay="flightsData.departure_display"
					:travelTime="flightsData.travel_time"
					:flightsData="outboundFlights"
					:co2="outboundTotalCo2"
					:allFlightsFlown="flightsData.allFlightsFlown"

					loadOpen="true"
				/>
				<div v-if="returnFlightsData">
					<div class="flights-date">
						{{ formatDate(returnFlightsData.departure * 1000) }}
					</div>
					<FlightCard
						class="flight-card"
						:name="'Flights to ' + returnFlightsData.destination"
						:origin="returnFlightsData.origin"
						:destination="returnFlightsData.destination"
						:layoversTotal="outboundFlights.length - 1"
						:layoverLocations="outboundLayoverLocations"
						:arrivalDisplay="returnFlightsData.arrival_display"
						:departureDisplay="returnFlightsData.departure_display"
						:travelTime="returnFlightsData.travel_time"
						:flightsData="returnFlights"
						:co2="returnTotalCo2"
						:allFlightsFlown="returnFlightsData.allFlightsFlown"

						loadOpen="true"
					/>
				</div>

				<div class="fares-container" ref="fares">
					<!-- Selected fare -->
					<div v-if="fareSelection && !editFare">
						<div class="heading">
							Selected Fare
							<img class="edit-section" @click="editFare = true" src="@/assets/flights-details/edit-fare.png" alt="Edit Fare Icon" />
						</div>
						<div class="fares">
							<FareCard
								:fareKey = fareSelection.key
								:fareName = fareSelection.name
								:cabinClass = fareSelection.cabin_class
								:origin = flightsData.origin
								:destination = flightsData.destination
								:baggage = fareSelection.baggage
								:cancellationPolicy = fareSelection.cancellation_policy
								:changePolicy = fareSelection.change_policy
								:amenities = fareSelection.description
								:descriptionMap = fareSelection.description_map
								:price = fareSelection.price

								:selected = true
								@changeFare="editFare = true; fareSelection = null;"
							/>
						</div>
					</div>

					<!-- All fare options -->
					<div class="confirm" v-if="flightsData.fares.length != 0 && editFare">
						<div class="heading">
							Confirm your fare
						</div>
						<!-- <pre>{{ flightsData.fares }}</pre> -->
						<div class="fares" v-for="(fare, index) in flightsData.fares" :key="index">
							<!-- <pre>{{ fare }}</pre> -->
							<FareCard	
								:fareKey = fare.key
								:fareName = fare.name
								:cabinClass = fare.cabin_class
								:origin = flightsData.origin
								:destination = flightsData.destination
								:baggage = fare.baggage
								:cancellationPolicy = fare.cancellation_policy
								:changePolicy = fare.change_policy
								:amenities = fare.description
								:descriptionMap = fare.description_map
								:price = fare.price

								:selectable = true
								@selected="selectFare(fare.key)"
							/>
						</div>
					</div>
				</div>
				
				<div class="carbon-section" v-if="flightsData.total_co2" >
					<div class="carbon" :style="{'background-image': 'url(' + getImage(carbon.img) + ')'}">
						<span>
							Your carbon footprint for this flight is:
						</span><br>
						<span class="total">
							{{ flightsData.total_co2 }}kg CO<span class="unit">2</span>
						</span>
					</div>

					<!-- <div class="points">
						You could earn <span>x points</span> by choosing this flight!
						Off set your Carbon Footprint for <span>£XX.XX</span>
					</div> -->
				</div>

				<button v-if="fareSelection != null" class="button blue save" @click="selectTrip()">Continue</button>
				<button v-else class="button save disabled" disabled>Continue</button>

				<button class="button share">Share</button>
				<!-- <div class="footer">
					<div class="price" v-if="fareSelection != null" v-html="fareSelection.price.symbol + fareSelection.price.amount"></div>
					<div class="price placeholder-text" v-else>Select a fare to continue</div>
					<button class="button continue" v-if="fareSelection != null" @click="selectTrip()">Continue</button>
					<button class="button small continue disabled" v-else disabled>Continue</button>
				</div> -->

				<!-- <div class="popup outer" v-if="showTripsPopup">
					<div class="inner" v-if="account.status != null && account.status.loggedIn != false">
						<div class="button create-btn" @click="saveTrip()">Create New Trip</div>
						<span class="select">Or select a trip to save to:</span>
						<div class="trip-btn" v-for="trip in basket.backendTrips" :key="trip.id">
							<span v-if="trip.trip_data && trip.trip_data.data && trip.trip_data.data.name">{{ trip.trip_data.data.name }}</span>
							<span v-else>Trip: {{ trip.id }}</span>
							<span class="select-btn" @click="saveTrip(trip.id)">Select</span>
						</div>
					</div>
					<div class="inner" v-else>
						To save this flight to a trip, you must first <br>
						<div class="button" @click="saveTrip()">Log in or Sign Up</div>
					</div>
					<div class="bg" @click="showTripsPopup = false"></div>
				</div> -->
			</div>
		</div>
	</div>
</template>
<style scoped>
	.outer-wrapper {
		padding-bottom: 60px;
	}
	.heading {
		font-size: 20px;
		font-weight: 700;
		color: #118AB2;
		margin: 30px 0px 16px 0px;
	}
	.flights-date {
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 10px;
		color: black;
	}
	.flight-card {
		margin-bottom: 20px;
	}

	.fares {
		margin: 0 auto;
		margin-bottom: 54px;
	}
	.fares .card {
		margin: 0 auto;
	}
	.edit-section {
		float: right;
		color: #118AB2;
		cursor: pointer;
		width: 32px;
	}

	.button.save, .button.share {
		display: block;
		margin: 0 auto;
	}
	.button.save {
		margin-top: 42px;
		margin-bottom: 18px;
	}
	/* .button.save.disabled {
		background-color: #E0E0E0;
	} */
	.button.share {
		background-color: #073B4C;
	}
	.footer {
		position: fixed;
		bottom: 0;
		width: 375px;
		border-top: 1px solid #E5E5E5;
		background-color: #fff;
		height: 52px;
		margin-bottom: 75px;
		padding: 14px 0px;
	}
	.footer .price {
		width: 50%;
		display: inline-block;
		margin-left: 34px;
		margin-top: 10px;
		font-size: 20px;
		font-weight: 700;
		text-align: center;
	}
	.footer .button.continue {
		position: absolute;
		right: 21px;
	}
	/* .footer .button.continue.disabled {
		background-color: #E0E0E0;
	} */
	.footer .button.continue.disabled:hover:after {
		position: relative;
		top: -106px;
		width: 300px;
		height: auto;
		background-color: #E0E0E0;
		content: 'Currently only "Save to Trip" button (above) is functional - direct bookings without a trip wrapper is not yet implemented.';
		display: block;
		color: #e30202;
		font-weight: normal;
		left: -180px;
		border-radius: 30px;
		line-height: 18px;
	}
</style>
<script>
	import { mapState, mapActions, mapGetters } from 'vuex';
	import router from '@/router';
	import helpers from '@/helpers/helpers.js';
	import FlightCard from "@/components/flights/FlightCard.vue";
	import Loader from '@/components/Loader'
	import FareCard from '@/components/flights/FareCard.vue';
	import FlightHeader from "@/components/flights/FlightHeader.vue";
	import track from '@/helpers/track.js'

	export default {
		name: 'flightsDetails',
		data() {
			return {
				carbon: {
					img: "flights-details/carbon-forest-bg",
				},
				score: {
					img: "flights-details/circle-score",
				},
				showTripsPopup: false,
				fareSelection: null,
				outboundKey: this.$route.params.outbound_key ? this.$route.params.outbound_key : null,
				returnKey: this.$route.params.return_key ? this.$route.params.return_key : null,
				isLoading: true,
				editFare: true,

				flightsData: null, // Direct from endpoint
				returnFlightsData: null, // Direct from endpoint
				outboundFlights: [], // Formatted for UI
				returnFlights: [], // Formatted for UI
				outboundTotalCo2: 0,
				returnTotalCo2: 0,
				outboundLayoverLocations: [],
				returnLayoverLocations: [],
				startDate: false,
				endDate: false,

				error: false,
			}
		},
		components: {
			FlightCard,
			Loader,
			FareCard,
			FlightHeader
		},
		computed: {
			...mapState({
				flightsSearch: state => state.flightsSearch,
				basket: state => state.basket,
				account: state => state.account,
				alert: state => state.alert,
			}),
		},
		methods: {
			formatDate: (date) => helpers.formatDate(date),
			...mapActions({
				alertError: "alert/error",
				alertSuccess: "alert/success",
			}),
			formatPrice: (currency, to, howmany) => helpers.formatPrice(currency, to, howmany),
			getImage: (img) => helpers.getImage(img),
			...mapActions({
				setShouldSearch: "flightsSearch/setShouldSearch",
				setSelectedFlight: "basket/setSelectedFlight",
				removeFromBasket: "basket/removeFromBasket",
				getFlights: "flightsSearch/getFlights",
			}),
			formatFlightsData() {
				let outboundFlights = [];
				let returnFlights = [];
				let returnTotalCo2 = 0;
				let outboundTotalCo2 = 0;

				for(const id in this.flightsData.flights) {
					let flight = this.flightsData.flights[id];
					let flightData = {
						departureTime: flight.segment_info.DepartureTime,
						arrivalTime: flight.segment_info.ArrivalTime,
						origin: flight.segment_info.Origin,
						originData: {
							name: flight.segment_info.Origin,
							code: flight.segment_info.Origin,
						},
						destination: flight.segment_info.Destination,
						destinationData: {
							name: flight.segment_info.Destination,
							code: flight.segment_info.Desination,
						},
						carrier: {
							code: flight.carrier.code,
							name: flight.carrier.name,
						},
						flightTime: flight.flight_time,
						flightNumber: flight.flight_number,
						changeOfPlane: flight.segment_info.ChangeOfPlane,
						co2: {
							amount: flight.co2,
							unit: flight.co2_unit,
						},
					}
					outboundTotalCo2 += flight.co2;
					outboundFlights.push(flightData);
				}

				if(this.returnFlightsData) {
					for(const id in this.returnFlightsData.flights) {
						let flight = this.returnFlightsData.flights[id];
						let flightData = {
							departureTime: flight.segment_info.DepartureTime,
							arrivalTime: flight.segment_info.ArrivalTime,
							origin: flight.segment_info.Origin,
							originData: {
								name: flight.segment_info.Origin,
								code: flight.segment_info.Origin,
							},
							destination: flight.segment_info.Destination,
							destinationData: {
								name: flight.segment_info.Destination,
								code: flight.segment_info.Desination,
							},
							carrier: {
								code: flight.carrier.code,
								name: flight.carrier.name,
							},
							flightTime: flight.flight_time,
							flightNumber: flight.flight_number,
							changeOfPlane: flight.segment_info.ChangeOfPlane,
							co2: {
								amount: flight.co2,
								unit: flight.co2_unit,
							},
						}
						returnTotalCo2 += flight.co2;
						returnFlights.push(flightData);
					}
				}
				
				this.outboundFlights = outboundFlights;
				this.returnFlights = returnFlights;
				this.outboundTotalCo2 = outboundTotalCo2;
				this.returnTotalCo2 = returnTotalCo2;

				this.outboundLayoverLocations = outboundFlights.map(x => x.destination);
				this.outboundLayoverLocations.pop();
				this.returnLayoverLocations = returnFlights.map(x => x.destination);
				this.returnLayoverLocations.pop();
			},
			selectFlight() {
				let airline = this.flightsData.carrier.code;
				let origin = this.flightsData.origin;
				let destination = this.flightsData.destination;

				this.setSelectedFlight({
					outboundKey: this.outboundKey,
					returnKey: this.returnKey,
					fareData: this.fareSelection,
					airline: airline,
					origin: origin,
					destination: destination,
					departure: this.flightsData.departure,
				});
			},
			selectTrip() {
				this.selectFlight();
				router.replace({
					name: "Flights Select Trip",
					params: {
						outbound_key: this.outboundKey,
						return_key: this.returnKey,
					}
				})
			},
			selectFare(fareKey) {
				this.fareSelection = this.flightsData.fares.find(item=>item.key == fareKey);
				this.editFare = false;
				try {
					track.event('selectFare', {
						itemType: 'flight',
						fareName: this.fareSelection.name,
						ecommerce: {
							value: this.fareSelection.price.amount,
							currency: this.fareSelection.price.currency,
						},
					});
				} catch(e) {
					console.error(e);
					this.$rollbar.error("Tracking: " + e);
				}
			},
			selected() {
				var select_btn = document.querySelector('#select-btn');
				var selected_btn = document.querySelector('#selected-btn');
				select_btn.classList.add('active');
				selected_btn.classList.remove('active');
			},
			select() {
				var select_btn = document.querySelector('#select-btn');
				var selected_btn = document.querySelector('#selected-btn');
				select_btn.classList.remove('active');
				selected_btn.classList.add('active');
			},
			init() {
				try {
					track.event('viewResult', {
						itemType: 'flight',
						itemID: false, // Only tours have applicable IDs
					});
				} catch(e) {
					console.error(e);
					this.$rollbar.error("Tracking: " + e);
				}
				if(!this.$route.params.return_key) {
					this.returnKey = null;
				}
				this.error = false;
				this.isLoading = true;
				this.getFlights({ outboundKey: encodeURIComponent(this.$route.params.outbound_key), returnKey: this.returnKey })
					.then(response => {
						this.flightsData = response.data.data;
						if(this.returnKey) {
							this.returnFlightsData = this.flightsData.return_options[this.returnKey];
						}
						// this.startDate = Object.keys(this.allFlights)[0];
						// this.endDate = Object.keys(this.allFlights)[Object.keys(this.allFlights).length - 1];
						// new Date(this.flightsData.flights[id].segment_info.DepartureTime);

						this.formatFlightsData();

						// Update the layout to use a white arrow now that the flight header has loaded.
						this.$emit('update:topLayoutSettings', {
							left: {
								type: 'back',
								color: 'white',
								route: { name: 'Flights Results' },
							},
							right: {
								type: 'none',
							},
							overlayContent: true,
						});
					})
					.catch(error => {
						this.error = error + ' Try selecting a different result.';
						if(error == "Session expired, please refresh the search") { // If the result simply expired, then we can just re-search
							this.setShouldSearch(true); // Set the search state to reconduct a search
							this.alertError("Session expired, re-searching..."); // Alert the user of the error
							try {
								track.event('flightExpired', {});
							} catch(e) {
								console.error(e);
								this.$rollbar.error("Tracking: " + e);
							}
							// Go back to the search page, which will trigger a new search
							router.push({
								name: "Flights Results",
							});
						}
					})
					.finally(() => {
						this.isLoading = false;
					});
			}
		},
		mounted() {

		},
		created() {
			this.$emit('update:topLayoutSettings', {
				left: {
					type: 'back',
					route: { name: 'Flights Results' },
				},
				right: {
					type: 'none',
				},
				overlayContent: false,
			});

			this.init();
		}
	}
</script>

